import React from 'react';
import { Box, Container, Typography, Link, Grid, IconButton, Divider } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import '../estilos/Footer.css';

const Footer = () => {
  return (
    <Box component="footer" py={3} mt={5}>
      <Container className="footer-container">
        <Grid container spacing={4} justifyContent="center">
          {/* Enlaces relacionados */}
          <Grid item xs={12} md={3}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography variant="h6" gutterBottom className="footer-title">
                Enlaces relacionados
              </Typography>
              <ul>
                <li><Link href="#" className="footer-link">Simulacro Examen Reglas</Link></li>
                <li><Link href="https://ceval.kalelsac.com/citas" className="footer-link">Reserva de citas</Link></li>
                <li><Link href="https://aulavirtual.kalelsac.com/" className="footer-link">Aula virtual</Link></li>
                <li><Link href="https://kalelsac.com/" className="footer-link">Tramites de Licencia</Link></li>
              </ul>
            </Box>
          </Grid>

          {/* Contacto */}
          <Grid item xs={12} md={4}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography variant="h6" gutterBottom className="footer-title">
                Contacto
              </Typography>
              <ul>
                <li>
                  <Link href="https://wa.me/1234567890" className="footer-link" target="_blank" rel="noopener noreferrer">
                    <WhatsAppIcon /> 925984699
                  </Link>
                </li>
                <li>
                  <Link href="https://wa.me/0987654321" className="footer-link" target="_blank" rel="noopener noreferrer">
                    <WhatsAppIcon /> 925984699
                  </Link>
                </li>
              </ul>
            </Box>
          </Grid>

          {/* Síguenos */}
          <Grid item xs={12} md={4}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography variant="h6" gutterBottom className="footer-title">
                Síguenos
              </Typography>
              <Box display="flex" justifyContent="center">
                <IconButton component={Link} href="https://facebook.com" className="footer-icon" target="_blank" rel="noopener noreferrer">
                  <FacebookIcon />
                </IconButton>
                <IconButton component={Link} href="https://twitter.com" className="footer-icon" target="_blank" rel="noopener noreferrer">
                  <TwitterIcon />
                </IconButton>
                <IconButton component={Link} href="https://instagram.com" className="footer-icon" target="_blank" rel="noopener noreferrer">
                  <InstagramIcon />
                </IconButton>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Divider sx={{ width: '100%', marginBottom: 2, borderColor: 'skyblue', borderWidth: '1px' }} />

        <Typography variant="body2" align="center" color="textSecondary" mt={4}>
          &copy; 2024 Kalel.Sac. Todos los derechos reservados.
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;
