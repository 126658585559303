import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Box, Container, IconButton, Menu, MenuItem, Grid } from '@mui/material';
import Logo from './Logo';
import DesktopMenu from './DesktopMenu';
import PhoneButton from '../header/PhoneButton';
import Nosotros from '../../paginas/Nosotros';
import Reservar from '../../paginas/Reservar';
import Evaluaciones from '../../paginas/Servicios';
import MenuIcon from '@mui/icons-material/Menu';

const MenuAppBar = ({ onLogoClick }) => {
  const [selectedComponent, setSelectedComponent] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (component) => {
    setSelectedComponent(component);
    handleMenuClose();
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 900) {
        handleMenuClose();
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
     {/* Contenedor del logo y los botones de WhatsApp */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: { xs: 'center', md: 'space-between' },
            alignItems: 'center',
            padding: { xs: '10px', md: '20px' },
            position: 'relative',
          }}
        >
          <Grid container spacing={2} alignItems="center">
            {/* Columna para el logo */}
            <Grid item xs={12} md={6} display="flex" justifyContent="flex-start">
              <Box
                sx={{
                  position: 'relative',
                  flexGrow: 1,
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Logo
                  sx={{
                    width: { xs: '100px', md: '150px' },
                    height: 'auto',
                  }}
                  onClick={onLogoClick}
                />
              </Box>
            </Grid>

            {/* Columna para los botones de WhatsApp */}
            <Grid item xs={12} md={6} display="flex" justifyContent="center">
              <Box
                sx={{
                  display: { xs: 'row', md: 'flex' },
                  alignItems: 'center',
                  justifyContent:'center',
                  gap: 1,
                }}
              >
                <PhoneButton number="925984699" />
                <PhoneButton number="925984699" />
              </Box>
            </Grid>
          </Grid>
        </Box>

      {/* AppBar debajo del logo, centrado y con ancho del 70% */}
      <AppBar
        position="static"
        sx={{
          color: 'black',
          width: '100%',
          margin: '0 auto',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
          overflow: 'hidden',
          textAlign: 'center',
          marginTop: '2px',
          display: { xs: 'none', md: 'block' },
        }}
      >
        <Container maxWidth="xl" disableGutters>
          <Toolbar
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              minHeight: '120px',
              padding: { xs: '0 10px', md: '0 20px' },
              width: '100%',
            }}
          >
            <Box
              sx={{
                flexGrow: 2,
                display: 'flex',
                justifyContent: 'center',
                padding: '0',
              }}
            >
              <DesktopMenu onMenuClick={handleMenuItemClick} />
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      {/* Icono de menú desplegable para dispositivos pequeños */}
      <Box
        sx={{
          display: { xs: 'block', md: 'none' }, // Mostrar solo en dispositivos pequeños
          position: 'absolute',
          top: '30px',
          right: '10px',
          zIndex: 1000,
          justifyContent:'center',
          alignItems:'center'
        }}
      >
        <IconButton
          onClick={handleMenuOpen}
          sx={{ color: 'blue', backgroundColor: 'transparent', width: '35px', height: '35px' }}
        >
          <MenuIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          PaperProps={{
            sx: {
              width: '100vw',
              maxWidth: '100vw',
              backgroundColor: 'blue',
              color: 'white',
              top: '0px',
              left: '0px',
              height: 'auto',
              marginLeft: '15px',
            },
          }}
        >
          <MenuItem sx={{ borderBottom: '1px solid white' }} onClick={() => handleMenuItemClick('Inicio')}>Inicio</MenuItem>
          <MenuItem sx={{ borderBottom: '1px solid white' }} onClick={() => handleMenuItemClick('nosotros')}>Nosotros</MenuItem>
          <MenuItem sx={{ borderBottom: '1px solid white' }} onClick={() => handleMenuItemClick('evaluaciones')}>Servicios</MenuItem>
          <MenuItem onClick={() => handleMenuItemClick('reservar')}>Reserva tu Cita</MenuItem>
        </Menu>
      </Box>

      {/* Renderiza el componente seleccionado */}
      {selectedComponent === 'nosotros' && <Nosotros />}
      {selectedComponent === 'reservar' && <Reservar />}
      {selectedComponent === 'evaluaciones' && <Evaluaciones />}
    </>
  );
};

export default MenuAppBar;
